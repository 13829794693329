import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getApplicationState } from "../../redux/reducers/applicationState/applicationStateSelector"
import { LogoOptimised, Logo } from "../svg"
import { Link } from "gatsby"
import { IconHamburger, IconClose } from "../svg"
import Navigation from "./Navigation"
import actions from "../../redux/actions-index"

export default () => {
  const dispatch = useDispatch()
  const { navigationActive } = useSelector(getApplicationState)

  const navigationToggle = () => {
    dispatch(actions.setNavigationActive(!navigationActive))
  }
  return (
    <header className="header container">
      <button
        className={`header__nav-toggle ${navigationActive ? "active" : ""}`}
        onClick={navigationToggle}
      >
        {navigationActive ? <IconClose /> : <IconHamburger />}
      </button>
      <Link className="header__link" to="/">
        <LogoOptimised className="header__logo desktop" />
        <Logo className="header__logo mobile" />
      </Link>
      <Navigation />
    </header>
  )
}
