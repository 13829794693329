import React from "react"

export default ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <path
            d="M655.302 354l.698.698L640.698 370 656 385.302l-.698.698L640 370.698 624.698 386l-.698-.698L639.301 370 624 354.698l.698-.698L640 369.301 655.302 354z"
            transform="translate(-624 -354)"
          />
        </g>
      </g>
    </svg>
  )
}
