import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ data, parent }) => {
  const { title, slug, mainImage } = data
  return (
    <div className="signpost-item">
      <Link to={`/${parent}/${slug}`} className="signpost-item__link">
        <div className="signpost-item__inner">
          <Img
            fluid={mainImage.fluid}
            className="signpost-item__image"
            style={{ position: "absolute" }}
          />
          <h2 className="signpost-item__title">{title}</h2>
        </div>
      </Link>
    </div>
  )
}
