import React from "react"
import { Link } from "gatsby"
import { Logo } from "../svg"
import FooterLinks from "./FooterLinks"

export default () => {
  const year = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <Link to="/">
            <Logo className="footer__logo" />
          </Link>
          <FooterLinks />
          <p className="footer__infotext">
            Registed office: The Barn, Bayton House Bayton Lane, Horsforth,
            Leeds, West Yorkshire, United Kingdom, LS18 5EY
          </p>
          <p className="footer__copyright">
            &copy; Solicom {year}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
