import React from "react"
import { useSelector } from "react-redux"
import { getApplicationState } from "../../redux/reducers/applicationState/applicationStateSelector"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import "../../stylesheets/style.scss"

export default ({ children }) => {
  const { navigationActive } = useSelector(getApplicationState)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <main>
        <Header />
        {navigationActive && <div className="overlay" />}
        {children}
        <Footer />
      </main>
    </>
  )
}
