import React from "react"
import { Link } from "gatsby"

export default ({ to, state, children, className }) => {
  return (
    <Link className={`icon-button ${className}`} to={to} state={{ state }}>
      {children}
    </Link>
  )
}
