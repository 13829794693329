import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

export default () => {
  const query = useStaticQuery(graphql`
    query FooterLinksQuery {
      allContentfulFooterLinks(limit: 1) {
        nodes {
          navigationElements {
            title
            page {
              ... on ContentfulExpertiseListing {
                slug
              }
              ... on ContentfulContactPage {
                slug
              }
            }
          }
        }
      }
    }
  `)

  const { navigationElements } = query.allContentfulFooterLinks.nodes[0]

  return (
    <nav className="footer-links">
      <Link className="footer-links__link" activeClassName="active" to="/">
        Home
      </Link>
      {navigationElements.map((item, index) => {
        return (
          <Link
            className="footer-links__link"
            activeClassName="active"
            to={`${item.page.slug}`}
            key={`FooterLink-${index}`}
          >
            {item.title}
          </Link>
        )
      })}
    </nav>
  )
}
