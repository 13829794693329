import React, { Fragment } from "react"
import { Link } from "gatsby"
import { IconButtonLink } from "./../buttons"
import { IconArrow } from "./../svg"

export default (props) => {
  let { data, className } = props
  const dataLength = data.length - 1
  // const prevPage = dataLength - 1

  if (!className || className === undefined) {
    className = ""
  }

  return (
    <div className={`breadcrumb border-bottom`}>
      <div className="breadcrumb__inner">
        <IconButtonLink to={"/"} className="-small breadcrumb__button">
          <IconArrow className="[ icon -flip icon--breadcrumb ]" />
        </IconButtonLink>
        {data.map((item, index) => {
          if (index === 0) {
            return (
              <Fragment key={index}>
                <Link
                  to={item._meta}
                  className="breadcrumb__link"
                  activeClassName="active"
                >
                  {item.title}
                </Link>
                <span className="breadcrumb__divider">/</span>
              </Fragment>
            )
          }
          if (index !== dataLength) {
            return (
              <Fragment key={index}>
                <Link
                  to={item._meta}
                  className="breadcrumb__link"
                  activeClassName="active"
                >
                  {item.title}
                </Link>
                <span className="breadcrumb__divider">/</span>
              </Fragment>
            )
          }

          return (
            <Link
              key={index}
              to={item._meta}
              className="breadcrumb__link"
              activeClassName="active"
            >
              {item.title}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
