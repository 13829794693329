import React, { useState, useRef, useEffect } from "react"

export default () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const form = useRef(null)

  useEffect(() => {
    if (formSubmitted) setFormSubmitted(false)
    if (isSuccess) setIsSuccess(false)
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    setFormSubmitted(true)
    const formData = new FormData(form.current)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setIsSuccess(true))
      .catch((error) => alert(error))
  }
  return (
    <>
      {!formSubmitted && (
        <form
          name="Solicom"
          className="form"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          ref={form}
        >
          <input type="hidden" name="name" value="Solicom" />
          <div className="form-inner">
            <div className="form-item -half-width">
              <input name="bot-field" type="hidden" />
              <label htmlFor="fullName">Name</label>
              <input
                type="text"
                id="fullName"
                placeholder="Full name"
                name="fullName"
                required
              />
            </div>
            <div className="form-item -half-width">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                placeholder="Company"
                name="company"
              />
            </div>
            <div className="form-item -half-width">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                placeholder="Email address"
                name="email"
                required
              />
            </div>
            <div className="form-item -half-width">
              <label htmlFor="telephone">Contact number</label>
              <input
                type="tel"
                id="telephone"
                name="telephone"
                placeholder="Contact number"
              />
            </div>
            <div className="form-item -full-width">
              <label htmlFor="message">Your message</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                name="message"
                placeholder="Please enter your message here"
              />
            </div>
          </div>
          <button type="submit" className="button" name="Submit">
            Submit
          </button>
        </form>
      )}
      {formSubmitted && isSuccess && (
        <div className="form">
          <div className="infobar">
            <p>Thank you. Your message has been submitted.</p>
          </div>
        </div>
      )}
    </>
  )
}
