import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ContactForm } from "."

export default ({ data }) => {
  const { title, subtext } = data
  return (
    <div className="contact">
      <div className="[ container -pinched ]">
        <h3 className="[ title -h3 -underline -center ]">
          <span>{title}</span>
        </h3>
        {subtext && renderRichText(subtext)}
        <ContactForm />
      </div>
    </div>
  )
}
