import React from "react"
import { Link } from "gatsby"
import Video from "../../video/hero2.mp4"
import videoImage from "../../video/poster.jpeg"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

export default ({ data, className }) => {
  const { title, subtext, button } = data
  return (
    <div className={`hero ${className}`}>
      <div className="hero__inner [ container -pinched ]">
        {title && (
          <h1 className="hero__title [ title h1 -white -underline -no-margin ]">
            <span>{title}</span>
          </h1>
        )}
        {subtext && (
          <div className="hero__content">
            {renderRichText(subtext, options)}
          </div>
        )}
        {button && (
          <div className="hero__button [ button-holder centered ]">
            <Link className="button" to="/expertise">
              How can we help you?
            </Link>
          </div>
        )}
      </div>
      <div className="hero__media">
        <video
          className="hero__video"
          preload="none"
          src={Video}
          poster={videoImage}
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline
        />
      </div>
    </div>
  )
}
