import React from "react"
import { Helmet } from "react-helmet"

export default ({ title, description }) => {
  return (
    <Helmet>
      <title>{title ? title : "Solicom"}</title>
      <meta
        name="description"
        content={description ? description : "Solicom"}
      />
    </Helmet>
  )
}
