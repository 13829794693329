import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

export default ({ data, className }) => {
  const { title, subtext, mainImage, button = false } = data
  return (
    <div className={`hero ${className}`}>
      <div className="hero__inner [ container -pinched ]">
        {title && (
          <h1 className="hero__title [ title h1 -white -underline -no-margin ]">
            <span>{title}</span>
          </h1>
        )}
        {button && (
          <div className="hero__button [ button-holder centered ]">
            <Link className="button" to={button.url}>
              {button.title}
            </Link>
          </div>
        )}
      </div>
      {mainImage && (
        <div className="hero__media">
          <Img fluid={mainImage.fluid} className="hero__image" />
        </div>
      )}
    </div>
  )
}
