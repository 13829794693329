import React from "react"
import CaseStudyItem from "./CaseStudyItem"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default ({ data }) => {
  const { title, link, subtext } = data
  return (
    <div className="case-studies section">
      <div className="[ container -pinched ]">
        <h2 className="[ title -h3 -center -underline ]">
          <span>{title}</span>
        </h2>
        {renderRichText(subtext)}
      </div>
      <div className="container">
        <div className="case-study-holder">
          {link?.page &&
            link.page.map((item, index) => {
              return (
                <CaseStudyItem
                  key={`caseStudyItem-${index}`}
                  data={item}
                  parent={link.slug}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}
