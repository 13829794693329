import React from "react"

export default (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43.227 43.227"
      height={163.378}
      width={163.378}
      {...props}
    >
      <path
        style={{
          mixBlendMode: "color",
        }}
        d="M21.476 0c5.659-.036 6.437 7.179 10.464 11.155 4.027 3.976 11.25 4.662 11.287 10.32.036 5.66-7.179 6.438-11.155 10.465-3.976 4.027-4.662 11.25-10.32 11.287-5.66.036-6.438-7.179-10.465-11.155C7.26 28.096.037 27.41 0 21.752c-.036-5.66 7.179-6.438 11.155-10.465C15.13 7.26 15.817.037 21.475 0z"
        fill="#50c297"
      />
      <path
        style={{
          mixBlendMode: "color",
        }}
        d="M36.825 5.966c4.072 3.93-.415 9.633-.315 15.29.1 5.659 4.786 11.2.856 15.271-3.93 4.072-9.634-.415-15.292-.315-5.658.1-11.199 4.786-15.27.856-4.072-3.93.415-9.633.315-15.291-.1-5.659-4.786-11.2-.856-15.271 3.93-4.072 9.634.416 15.292.316 5.658-.1 11.199-4.787 15.27-.856z"
        fill="#d9d9d9"
      />
      <path
        d="M21.387 28.466q-.897 0-1.719-.144-.82-.136-1.524-.373-.694-.237-1.244-.516-.55-.288-.906-.576l1.846-3.167q.372.254.812.491.45.237.932.424.482.186.965.296.491.11.948.11.424 0 .711-.101.288-.102.44-.314.153-.22.153-.542 0-.321-.16-.558-.153-.246-.492-.475-.338-.228-.889-.5-.55-.27-1.338-.643-.6-.287-1.092-.651-.482-.373-.83-.822-.338-.448-.524-.973-.178-.525-.178-1.118 0-.83.347-1.6.347-.78.99-1.397.644-.618 1.541-.982.906-.364 2.016-.364.71 0 1.507.152.804.152 1.557.423.754.271 1.321.627l-1.473 2.997q-.44-.254-.931-.432-.491-.186-.974-.28-.483-.1-.914-.1-.415 0-.712.1-.296.094-.457.289-.16.186-.16.474 0 .186.076.33.084.136.262.254.186.119.491.254l.745.33q1.092.483 1.914.932.82.448 1.363.965.55.508.821 1.16.271.652.271 1.532 0 1.406-.677 2.413-.67 1.008-1.905 1.541-1.236.534-2.93.534z"
        fontSize={16.933}
        aria-label="S"
        fontWeight={900}
        fontFamily="Spartan"
        fill="#fff"
        strokeWidth={0.265}
      />
    </svg>
  )
}
