import React from "react"
import { useSelector } from "react-redux"
import { getApplicationState } from "../../redux/reducers/applicationState/applicationStateSelector"
import { Link, graphql, useStaticQuery } from "gatsby"

export default () => {
  const { navigationActive } = useSelector(getApplicationState)

  const query = useStaticQuery(graphql`
    query NavigationQuery {
      allContentfulNavigation(limit: 1) {
        nodes {
          navigationElements {
            title
            page {
              ... on ContentfulPage {
                slug
              }
              ... on ContentfulCaseStudyListing {
                slug
              }
              ... on ContentfulExpertiseListing {
                slug
              }
            }
          }
        }
      }
    }
  `)

  const { navigationElements } = query.allContentfulNavigation.nodes[0]

  return (
    <nav className={navigationActive ? "navigation active" : "navigation"}>
      <Link className="navigation__link" activeClassName="active" to="/">
        Home
      </Link>
      {navigationElements.map((item, index) => {
        return (
          <Link
            className="navigation__link"
            activeClassName="active"
            to={`/${item.page.slug}`}
            key={`navigationItem-${index}`}
          >
            {item.title}
          </Link>
        )
      })}
      <Link className="button" activeClassName="active" to="/contact">
        <span>Contact</span>
      </Link>
    </nav>
  )
}
