import React from "react"
import { Link } from "gatsby"
import ExpertiseItem from "./ExpertiseItem"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default ({ data }) => {
  const { title, link, slug, subtext } = data
  return (
    <div className="content">
      <div className="[ container -pinched ]">
        {title && (
          <h2 className="[ title -h2 -center -underline ]">
            <span>{title}</span>
          </h2>
        )}
        {subtext && renderRichText(subtext)}
      </div>
      <div className="container">
        <div className="signpost">
          {link.page.map((item, index) => {
            return (
              <ExpertiseItem
                key={`expertiseItem-${index}`}
                data={item}
                parent={link.slug}
              />
            )
          })}
        </div>
      </div>
      <div className="button-holder -centered">
        <Link to={link.slug} className="button">
          View all {link.slug}
        </Link>
      </div>
    </div>
  )
}
