import React, { Fragment } from "react"
import { Link } from "gatsby"
import { LinkResolver } from "./../../utils"
import Img from "gatsby-image"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

// Sort and display the different slice options
const StandardContentSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.internal.type) {
        case "ContentfulPageCopy":
          return <Fragment key={index}>{renderRichText(slice.body)}</Fragment>
        case "ContentfulPageImage":
          return (
            <Img
              key={index}
              className="standout-image"
              fluid={slice.image.fluid}
            />
          )
        case "ContentfulPageStandoutBlock":
          return (
            <div className="standout-text" key={index}>
              {renderRichText(slice.body)}
            </div>
          )

        default:
          return <h1>Missing slice - {slice.internal.type}</h1>
      }
    })()
    return res
  })
}

export default ({ body }) => {
  return <StandardContentSlices slices={body} />
}
