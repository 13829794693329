import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { IconButton } from "../buttons"
import { IconArrow } from "../svg"

export default ({ data, parent }) => {
  const { title, slug, mainImage } = data
  return (
    <div className="case-study">
      <Link to={`/${parent}/${slug}`} className="case-study__link">
        <div className="case-study__content">
          <h3 className="[ title h4 ]">{title}</h3>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
            molestias quasi impedit, ea natus dignissimos exercitationem non
            quibusdam necessitatibus, deserunt sunt quisquam aspernatur
            consectetur sequi dicta tempore temporibus, ipsam qui.
          </p>
          <IconButton className="case-study__button">
            <IconArrow />
          </IconButton>
        </div>
        <div className="case-study__image">
          <Img fluid={mainImage.fluid} />
        </div>
      </Link>
    </div>
  )
}
